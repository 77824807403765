import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Auth } from "../../../ts/Auth";
import { CenovaPonuka as CenovaPonukaType, SearchQuery } from "../../../ts/CP";
import ReduxState from "../../../ts/Redux";
import CenovaPonuka from "./CenovaPonuka/CenovaPonuka";
import "./CenovePonuky.scss";
import Header from "./Header";

import { GET_CP, EDIT_CP, CREATE_CP } from "../../../redux/actions/cpActions";
import SeznamPonuk from "./SeznamPonuk";
import { getCPState } from "../../../utils";
import { RouteComponentProps, useHistory } from "react-router";
import Loading from "../../ui/Loading/Loading";
import axios from "../../../axios/axios";
import { Kontakt } from "../../../ts/Kontakt";

const CenovePonukyContext = React.createContext<
  | [
      value: CenovaPonukaType,
      setValue: (
        cenovaPonuka: CenovaPonukaType,
        success?: (state: boolean) => void
      ) => void,
      setIndex: (value: number) => void,
      addNewCP: (
        cp: CenovaPonukaType,
        success: (state: boolean, id: string) => void
      ) => void
    ]
  | []
>([]);

const EditableContext = React.createContext<
  [
    editable: boolean,
    setEditable: (editable: boolean) => void,
    listShown: boolean,
    setListShown: (state: boolean) => void,
    newCP: boolean,
    setNewCP: (editable: boolean) => void
  ]
>([false, () => null, false, () => null, false, () => null]);

export const useCenovaPonuka = () => {
  return React.useContext(CenovePonukyContext);
};

export const useEditable = () => {
  return React.useContext(EditableContext);
};

interface Props extends RouteComponentProps<{ id: string; kontakt: string }> {
  auth: Auth;
  cp: {
    loading: boolean;
    list: CenovaPonukaType[];
  };
  GET_CP(id: string): void;
  EDIT_CP(
    cp: CenovaPonukaType,
    auth: Auth,
    success?: (state: boolean) => void
  ): void;
  CREATE_CP(
    cp: CenovaPonukaType,
    id: string,
    success: (state: boolean, id: string) => void
  ): void;
}

interface SeznamPonukRef {
  search(query: SearchQuery): void;
}

const CenovePonuky: React.FC<Props> = ({
  auth,
  cp,
  GET_CP,
  EDIT_CP,
  CREATE_CP,
  match,
}: Props) => {
  const [index, setIndex] = useState<number>(0);
  const [editable, setEditable] = useState(false);
  const [listShown, setListShown] = useState(false);
  const [newCP, setNewCP] = useState(false);

  const history = useHistory();

  const seznamPonuk = useRef<SeznamPonukRef>(null);

  const handleSearch = (query: SearchQuery) => {
    setListShown(true);
    seznamPonuk.current?.search(query);
  };

  useEffect(() => {
    if (cp.list.length > 0) {
      let foundIndex = cp.list.findIndex((cp) => cp.id === match.params.id);

      if (foundIndex !== -1) {
        if (listShown) setListShown(false);
        setIndex(foundIndex);
        return;
      }
      history.push("/cenove-ponuky");
    }
  }, [cp, match.params.id]);

  useEffect(() => {
    if (match.params.id) {
      let foundIndex = cp.list.findIndex((cp) => cp.id === match.params.id);

      if (index !== foundIndex) history.push("/cenove-ponuky");
    }
  }, [listShown, index, match.params.id, cp]);

  useEffect(() => {
    const setSearchQuery = async () => {
      const req = await axios.get<Kontakt>("/kontakty/get-by-id", {
        params: {
          kontakt: match.params.kontakt,
        },
      });

      setListShown(true);
      seznamPonuk.current?.search({
        objednavatel: req.data.nazev,
        cislo_ponuky: "",
        prijimatel: "",
      });
    };

    if (match.params.kontakt) setSearchQuery();
  }, [match.params.kontakt]);

  useEffect(() => {
    if (cp.list.length === 0 && auth.user?.id) {
      GET_CP(auth.user.id);
    }
  }, [cp.list, auth.user.id]);

  if (cp.loading)
    return <Loading text="Získávanie cenových ponuk zo servera" />;

  return (
    <CenovePonukyContext.Provider
      value={[
        cp.list[index],
        (cenovaPonuka: CenovaPonukaType, success) =>
          EDIT_CP(cenovaPonuka, auth, success),
        (value: number) =>
          setIndex(
            value > cp.list.length - 1 ? index : value < 0 ? index : value
          ),
        (_cp: CenovaPonukaType, success) =>
          CREATE_CP(_cp, auth.user.id, success),
      ]}
    >
      <div className="cenove-ponuky">
        <div className="title-wrapper">
          {cp.list.length === 0 && !cp.loading ? null : (
            <div className="title">Cenová ponuka</div>
          )}
          {getCPState(cp.list[index]?.state) ? (
            <div className="state">
              Stav: <span>{getCPState(cp.list[index].state)}</span>
            </div>
          ) : null}
        </div>
        <EditableContext.Provider
          value={[
            editable,
            setEditable,
            listShown,
            setListShown,
            newCP,
            setNewCP,
          ]}
        >
          <Header
            length={cp.list.length}
            index={index}
            handleSearch={handleSearch}
          />
          {cp.list.length === 0 && !cp.loading && !newCP ? (
            <div className="cp-not-found">Žiadne cenové ponuky sa nenašli</div>
          ) : (
            <>
              <SeznamPonuk ref={seznamPonuk} shown={listShown} />
              <CenovaPonuka shown={!listShown} />
            </>
          )}
        </EditableContext.Provider>
      </div>
    </CenovePonukyContext.Provider>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  auth: state.auth,
  cp: state.cp,
});

export default connect(mapStateToProps, { GET_CP, EDIT_CP, CREATE_CP })(
  CenovePonuky
);
