import React from "react";

import { VscEdit } from "react-icons/vsc";
import { RiDeleteBin7Line, RiCloseLine } from "react-icons/ri";
import { IconButton } from "@material-ui/core";
import { FiCheck } from "react-icons/fi";
import { CSSProperties } from "react";

interface Props {
  active: boolean;
  handleEdit?(): void;
  handleRemove(): void;
  confirm(): void;
  close(): void;
  disabled?: boolean;
  style?: CSSProperties;
  isDeletable?: boolean;
}

const Actions: React.FC<Props> = ({
  active,
  handleEdit,
  handleRemove,
  confirm,
  close,
  isDeletable,
  disabled = false,
  style,
}: Props) => {
  const canDelete = isDeletable === undefined || Boolean(isDeletable);

  return (
    <td className="actions" style={style}>
      <div>
        {active ? (
          <>
            <IconButton onClick={confirm}>
              <FiCheck color="#444" />
            </IconButton>

            <IconButton onClick={close}>
              <RiCloseLine color="#444" />
            </IconButton>
          </>
        ) : (
          <>
            {handleEdit ? (
              <IconButton
                disabled={disabled}
                style={{
                  opacity: disabled ? 0.5 : 1,
                }}
                onClick={handleEdit}
              >
                <VscEdit color="#444" />
              </IconButton>
            ) : null}

            {handleRemove && canDelete && (
              <IconButton
                disabled={disabled}
                style={{
                  opacity: disabled ? 0.5 : 1,
                }}
                onClick={handleRemove}
              >
                <RiDeleteBin7Line color="#444" />
              </IconButton>
            )}
          </>
        )}
      </div>
    </td>
  );
};

export default Actions;
