import classNames from "classnames";
import React, {
  CSSProperties,
  forwardRef,
  ReactElement,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { HiOutlineChevronUp } from "react-icons/hi";
import { Column, Row as RowType } from "../../../ts/UI";
import { translateString } from "../../../utils";
import NewRow from "./NewRow";
import Row from "./Row";
import "./Table.scss";

import TableFooter from "./TableFooter";

interface Props {
  data: any[];
  columns: Column[];
  sortingColumns?: number[];
  actionsDisabled?: boolean;
  actions?:
    | boolean
    | {
        custom: boolean;
        arrayOfActions: JSX.Element | ((rowData: any) => JSX.Element);
      };
  rowClasses?: string[];
  rowClickHandler?(row: RowType): void;
  newRowHandler?(record: any): void;
  onNewRowCancel?(): void;
  deleteHandler?(record: any): void;
  isDeletable?(record: any): boolean;
  editHandler?(record: any): void;
  visible?: boolean;
}

const isUndefinedOrEmpty = (obj: any) => {
  return obj === undefined || "";
};

const sortByColumn = (
  a: any,
  b: any,
  index: number,
  ascending: boolean
): number => {
  let keys = Object.keys(a);

  let objectA = a[keys[index]],
    objectB = b[keys[index]];

  if (isUndefinedOrEmpty(objectA) && isUndefinedOrEmpty(objectB)) return 1;

  if (
    (typeof objectA === "number" && typeof objectB === "number") ||
    (parseInt(objectA) !== Number.NaN && parseInt(objectB) !== Number.NaN)
  ) {
    if (ascending) return parseInt(objectB) - parseInt(objectA);

    return parseInt(objectA) - parseInt(objectB);
  }

  return 0;
};

type InputData = {
  popis: string;
  kod: string;
  sirka: number;
  vyska: number;
};

const Table = forwardRef(
  (
    {
      data,
      columns,
      actions = false,
      actionsDisabled = false,
      sortingColumns = [],
      rowClickHandler,
      rowClasses,
      onNewRowCancel,
      newRowHandler,
      deleteHandler,
      editHandler,
      isDeletable,
      visible = true,
    }: Props,
    ref
  ): ReactElement<Props> => {
    const [currentData, setCurrentData] = useState(data);
    const [searchQuery, setSearchQuery] = useState<InputData | null>(null);
    const [sortingByColumn, setSortingByColumn] = useState<{
      ascending: boolean;
      index: number;
    } | null>(null);
    const [searchData, setSearchData] = useState<any[] | null>(null);
    const [filteredData, setFilteredData] = useState<any[] | null>(null);
    const [page, setPage] = useState(0);
    const [numberPerPage, setNumberPerPage] = useState(5);
    const [addNew, setAddNew] = useState(false);

    const dataLength = searchData
      ? searchData.length
      : filteredData
      ? filteredData.length
      : currentData
      ? currentData.length
      : 0;

    useEffect(() => {
      setCurrentData(data);
      if (searchData && searchQuery) filterByQuery(searchQuery);
    }, [data]);

    useEffect(() => {
      if (dataLength === 0 && page !== 0) {
        setPage(0);
        return;
      }

      if (page < 0 && dataLength !== 0) setPage(0);

      if (
        page > Math.floor((dataLength - 1) / numberPerPage) &&
        dataLength !== 0
      )
        setPage(Math.floor((dataLength - 1) / numberPerPage));
    }, [page]);

    useEffect(() => {
      if (actionsDisabled) setAddNew(false);
    }, [actionsDisabled]);

    useImperativeHandle(
      ref,
      () => ({
        addNewRow() {
          setAddNew(true);
        },
        getData() {
          return currentData;
        },
        removeFilter() {
          setSearchQuery(null);
          setSearchData(null);
        },
        filterByInput(inputData: InputData) {
          setSearchQuery(inputData);
          filterByQuery(inputData);
        },
      }),
      [currentData]
    );

    const filterByQuery = (query: InputData) => {
      if (
        query.kod === "" &&
        query.popis === "" &&
        Number.isNaN(query.sirka) &&
        Number.isNaN(query.vyska)
      ) {
        return;
      }
      setSearchData(
        currentData.filter((item) => {
          let keep = true,
            mustMatch = [];

          if (query.kod && query.kod !== "") mustMatch.push("kod");

          if (query.sirka && query.sirka !== 0) mustMatch.push("sirka");

          if (query.vyska && query.vyska !== 0) mustMatch.push("vyska");

          if (query.popis && query.popis !== "") mustMatch.push("popis");

          mustMatch.forEach((m) => {
            if (m === "sirka" || m === "vyska") {
              if (item[m] === undefined || !item[m]) {
                keep = false;
              } else if (!(item[m].toString() === query[m].toString())) {
                keep = false;
              }
            } else {
              if (!item[m].toLowerCase || !(query as any)[m].toLowerCase) {
                keep = false;
              } else if (
                !translateString(item[m].toLowerCase()).includes(
                  translateString((query as any)[m].toLowerCase())
                )
              ) {
                keep = false;
              }
            }
          });

          return keep;
        })
      );
    };

    const filterByColumn = (ascending: boolean, index: number) => {
      if (sortingByColumn && sortingByColumn?.index !== index) {
        setSortingByColumn({
          index,
          ascending: true,
        });
        setFilteredData(
          [...data].sort((a, b) => sortByColumn(a, b, index, ascending))
        );
        return;
      }

      if (sortingByColumn?.ascending === false) {
        setSortingByColumn(null);
        setFilteredData(null);
        return;
      }

      setSortingByColumn({
        index: index,
        ascending: ascending,
      });

      setFilteredData(
        [...data].sort((a, b) => sortByColumn(a, b, index, ascending))
      );
    };

    const getBody = (data: any[]) => {
      let indexesRange = [
          page * numberPerPage,
          page * numberPerPage + numberPerPage,
        ],
        indexedData: any[] = [],
        columnKeys = columns.map((c) => c.key);

      if (page * numberPerPage >= data.length && !addNew) {
        let calculated = Math.round(data.length / numberPerPage - 1);

        if (calculated >= 0)
          setPage(Math.round(data.length / numberPerPage - 1));

        setPage(0);
      }

      for (let i = indexesRange[0]; i < indexesRange[1]; i++)
        if (data[i]) {
          indexedData[i] = Object.keys(data[i])
            .filter((key) => columnKeys.includes(key))
            .map((key) => {
              let column = columns.find((c) => c.key === key);
              return {
                key,
                value: data[i][key],
                cellStyle: column?.cellStyle,
                visible: column?.visible,
                editable: column?.editable,
                placeholder: column?.placeholder,
                type: column?.type,
              };
            });

          let sortedIndexedData: any[] = [];

          columnKeys.map((cKey) => {
            let foundKey = indexedData[i].find((k: any) => k.key === cKey);

            if (foundKey) sortedIndexedData.push(foundKey);
          });

          indexedData[i] = sortedIndexedData;
        }

      return indexedData.map((item, index) => (
        <Row
          isDeletable={isDeletable}
          rowClickHandler={rowClickHandler}
          rowClasses={rowClasses}
          key={index}
          actions={actions}
          actionsDisabled={actionsDisabled}
          handleRowChange={
            editHandler
              ? (item) => {
                  if (editHandler) editHandler(item);
                }
              : undefined
          }
          handleDelete={(item) => {
            if (deleteHandler) deleteHandler(item);
          }}
          passedItem={item}
        />
      ));
    };

    return (
      <div className={classNames("tableWrapper", { visible })}>
        <table className={classNames("main-table", { disabled: addNew })}>
          <thead>
            <tr>
              {columns.map((column, index) =>
                column.visible === false ? null : column.actions ? (
                  <th
                    key={index}
                    style={{
                      cursor: "default",
                      width: column.width ? column.width : "auto",
                    }}
                  >
                    {column.title}
                  </th>
                ) : (
                  <th
                    key={index}
                    onClick={() => {
                      if (sortingColumns.includes(index))
                        filterByColumn(!sortingByColumn?.ascending, index);
                    }}
                    style={{
                      width: column.width ? column.width : "auto",
                    }}
                  >
                    <div>
                      <div className="th-title">{column.title}</div>
                      <div
                        className={classNames("chevronIcon", {
                          visible: sortingByColumn?.index === index,
                          descending: sortingByColumn?.ascending,
                        })}
                      >
                        <HiOutlineChevronUp />
                      </div>
                    </div>
                  </th>
                )
              )}
            </tr>
          </thead>
          {(dataLength !== 0 && dataLength) || (addNew && newRowHandler) ? (
            <tbody>
              {getBody(
                filteredData !== null
                  ? filteredData
                  : searchData !== null
                  ? searchData
                  : currentData
              )}
              {addNew && newRowHandler ? (
                <NewRow
                  confirm={(record: any) => {
                    if (Array.isArray(currentData))
                      setCurrentData([...currentData, record]);
                    else setCurrentData([record]);
                    newRowHandler(record);
                    setAddNew(false);
                  }}
                  cancel={() => {
                    setAddNew(false);
                    onNewRowCancel?.();
                  }}
                  columns={columns}
                />
              ) : null}
            </tbody>
          ) : null}
        </table>
        {(dataLength === 0 || !dataLength) && !addNew ? (
          <div
            className="emptyTable"
            style={{
              padding: addNew ? "10px 0" : "40px 0",
            }}
          >
            <span>Neboli nájdené žiadne výsledky</span>
          </div>
        ) : null}
        <TableFooter
          range={[
            page * numberPerPage + 1,
            (page + 1) * numberPerPage > dataLength
              ? dataLength
              : (page + 1) * numberPerPage,
            dataLength,
          ]}
          firstPage={() => setPage(0)}
          nextPage={
            page + 2 > Math.ceil(dataLength / numberPerPage)
              ? () => null
              : () => setPage(page + 1)
          }
          previousPage={page - 1 < 0 ? () => null : () => setPage(page - 1)}
          lastPage={() => setPage(Math.floor((dataLength - 1) / numberPerPage))}
          numberPerPage={numberPerPage}
          setNumberPerPage={(value: number) => {
            setPage(Math.floor((page * numberPerPage) / value));
            setNumberPerPage(value);
          }}
        />
      </div>
    );
  }
);

export default Table;
