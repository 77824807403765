import classNames from "classnames";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Portal } from "react-portal";
import Button from "../Button/Button";
import "./ImportPopup.scss";

import {
  IoAlertCircleOutline,
  IoCheckmarkCircleOutline,
} from "react-icons/io5";
import Row from "./Row";

export interface ImportPopupInterface {
  showPopup(_content: any[], _callback: (content: any[]) => void): void;
  showState(success: boolean): void;
}

interface Props {
  preventClosing?: boolean;
  canBeEdited?: boolean;
}

const ImportPopup = forwardRef(
  ({ preventClosing, canBeEdited }: Props, ref) => {
    const callback = useRef<null | ((c: any[]) => void)>(null);

    const [content, setContent] = useState<any[]>([]);
    const [chosen, setChosen] = useState<number[]>([]);
    const [successShown, setSuccessShown] = useState(false);
    const [state, setState] = useState(false);

    useImperativeHandle(
      ref,
      () => ({
        showPopup(_content: any[], _callback: (content: any[]) => void) {
          setContent(_content);
          setChosen(_content.map((_, i) => i));
          callback.current = _callback;
        },
        showState(success: boolean) {
          setState(success);
          setSuccessShown(true);
        },
      }),
      []
    );

    useEffect(() => {
      const handleEscKeyPress = (e: KeyboardEvent) => {
        if (e.key === "Escape") setContent([]);
      };

      if (content.length !== 0)
        document.addEventListener("keydown", handleEscKeyPress);
      else document.removeEventListener("keydown", handleEscKeyPress);
    }, [content.length]);

    const okHandler = () => {
      if (!callback.current) {
        setContent([]);
        return;
      }

      if (chosen.length === content.length) {
        callback.current(content);
        if (!preventClosing) setContent([]);
        return;
      }

      callback.current(
        content.filter((c, i) => {
          if (chosen.includes(i)) return true;
          return false;
        })
      );
      if (!preventClosing) setContent([]);
    };

    return (
      <Portal node={document && document.getElementById("root")}>
        <div
          className={classNames("import-popup", {
            visible: content?.length !== 0,
          })}
        >
          <div className="import-container">
            {successShown ? (
              <div className="state-wrapper">
                <div>
                  <div className="text">
                    {state ? (
                      <span>
                        <IoCheckmarkCircleOutline color="rgba(19, 181, 34, 0.8)" />
                        Kontakty boli úspešne pridané.
                      </span>
                    ) : (
                      <span>
                        <IoAlertCircleOutline color="hsla(0, 81.73515981735159%, 42.94117647058824%, 0.8)" />
                        Pri importe nastala chyba.
                      </span>
                    )}
                  </div>
                  <div>
                    <Button onClick={() => setContent([])}>OK</Button>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="header">
                  Zvolte záznamy v importu a potvrďte
                </div>

                <div className="content-table-wrapper">
                  <table className="content-table">
                    <thead>
                      <tr>
                        <td>IMPORT</td>
                        {content.length > 0
                          ? Object.keys(content[0]).map((key) => <td>{key}</td>)
                          : null}
                        {canBeEdited ? (
                          <>
                            <td />
                          </>
                        ) : null}
                      </tr>
                    </thead>
                    <tbody>
                      {content.map((obj, i) => (
                        <Row
                          content={content}
                          setContent={setContent}
                          key={i}
                          obj={obj}
                          setChosen={setChosen}
                          chosen={chosen}
                          canBeEdited={!!canBeEdited}
                          index={i}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>

                <div className="footer">
                  <Button onClick={okHandler}>OK</Button>
                  <Button onClick={() => setContent([])}>Zrušit</Button>
                </div>
              </>
            )}
          </div>
        </div>
      </Portal>
    );
  }
);

export default ImportPopup;
