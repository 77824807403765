import React, { useEffect, useRef } from "react";
import "./MainSwitch.scss";

import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import PrivateRoute from "../../routes/PrivateRoute";
import { Auth } from "../../ts/Auth";
import Cenik from "../main/Cenik/Cenik";
import CenovePonuky from "../main/CenovePonuky/CenovePonuky";
import Uvod from "../main/Uvod/Uvod";
import Obraty from "../main/Obraty/Obraty";
import Pouzivatelia from "../main/Pouzivatelia/Pouzivatelia";
import Skupiny from "../main/Skupiny/Skupiny";
import ReduxState from "../../ts/Redux";
import { connect } from "react-redux";
import classNames from "classnames";
import ZFaDL from "../main/ZFaDL/ZFaDL";
import KontaktyWrapper from "../main/Kontakty/KontaktyWrapper";
import AdminRoute from "../../routes/AdminRoute";

interface Props {
  auth: Auth;
}

const MainSwitch: React.FC<Props> = ({ auth }: Props) => {
  const location = useLocation();
  const wrapperRef = useRef<HTMLDivElement>(null);

  const transitionEnd = () => {
    wrapperRef.current?.classList.add("moved-in");
    wrapperRef.current?.classList.remove("move-in");
  };

  useEffect(() => {
    if (auth.logged) {
      wrapperRef.current?.classList.remove("transition");
      wrapperRef.current?.classList.remove("move-in");
      wrapperRef.current?.classList.remove("moved-in");

      setTimeout(() => {
        wrapperRef.current?.classList.add("move-in");
        wrapperRef.current?.classList.add("transition");
      }, 10);

      wrapperRef.current?.addEventListener("transitionend", transitionEnd);
      return () => {
        wrapperRef.current?.removeEventListener("transitionend", transitionEnd);
      };
    }
  }, [location.pathname, auth.logged]);

  return (
    <div
      className={classNames("switchWrapper", { noAnimation: !auth.logged })}
      ref={wrapperRef}
    >
      <Switch>
        <PrivateRoute exact path="/" component={Uvod} />
        <PrivateRoute exact path="/cennik" component={Cenik} />
        <PrivateRoute exact path="/kontakty" component={KontaktyWrapper} />
        <PrivateRoute exact path="/cenove-ponuky" component={CenovePonuky} />
        <PrivateRoute
          exact
          path="/cenove-ponuky/:id"
          component={CenovePonuky}
        />
        <PrivateRoute
          exact
          path="/cenove-ponuky/k/:kontakt"
          component={CenovePonuky}
        />
        <PrivateRoute exact path="/obraty" component={Obraty} />
        <PrivateRoute exact path="/obraty/k/:kontakt" component={Obraty} />
        <PrivateRoute exact path="/cs-skupiny" component={Skupiny} />
        <AdminRoute exact path="/pouzivatelia" component={Pouzivatelia} />
        <PrivateRoute exact path="/zfadl" component={ZFaDL} />
        <Route path="*" component={() => <Redirect to="/" />} />
      </Switch>
    </div>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(MainSwitch);
