import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Auth } from "../../../ts/Auth";
import ReduxState from "../../../ts/Redux";
import "./Uvod.scss";

import { LOGOUT_USER } from "../../../redux/actions/authActions";
import SeznamPonuk from "../CenovePonuky/SeznamPonuk";
import { GET_CP } from "../../../redux/actions/cpActions";
import { CenovaPonuka } from "../../../ts/CP";

interface Props {
  auth: Auth;
  cp: {
    loading: boolean;
    list: CenovaPonuka[];
  };
  LOGOUT_USER(): void;
  GET_CP(id: string): void;
}

const Uvod: React.FC<Props> = ({ auth, cp, LOGOUT_USER, GET_CP }: Props) => {
  useEffect(() => {
    if (cp.list.length === 0 && auth.user?.id) {
      GET_CP(auth.user.id);
    }
  }, [cp.list, auth.user?.id]);

  return (
    <div className="uvod">
      <div className="up">
        {auth.user && (
          <div className="name">
            {auth.user.firstName} {auth.user.lastName}
          </div>
        )}
        <div>
          <Link to="/cenove-ponuky">Cenové ponuky</Link>
        </div>
        <div>
          <Link to="/zfadl">Zálohové faktúry</Link>
        </div>
        <div>
          <Link to="/zfadl">Dodacie listy</Link>
        </div>
        <div className="logout" onClick={LOGOUT_USER}>
          Odhlásiť sa
        </div>
      </div>
      <div className="down">
        <div className="title">NOVÉ CENOVÉ PONUKY</div>
        <SeznamPonuk shown onlyNew />
      </div>
    </div>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  auth: state.auth,
  cp: state.cp,
});

export default connect(mapStateToProps, { LOGOUT_USER, GET_CP })(Uvod);
