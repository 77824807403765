import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import axios from "../../../axios/axios";
import { Auth } from "../../../ts/Auth";
import ReduxState from "../../../ts/Redux";
import { Cell, Column, FetchedObchodnik, TableReference } from "../../../ts/UI";
import Loading from "../../ui/Loading/Loading";
import Table from "../../ui/Table/Table";
import "./Pouzivatelia.scss";
import { IconButton } from "@material-ui/core";
import { FaPen } from "react-icons/fa";
import Button from "../../ui/Button/Button";
import { IoMdAdd, IoMdClose } from "react-icons/io";

interface Props {
  auth: Auth;
}

const Pouzivatelia: React.FC<Props> = ({ auth }: Props) => {
  const [obchodnici, setObchodnici] = useState<FetchedObchodnik[]>([]);
  const [loading, setLoading] = useState(false);
  const [isAddingNewRow, setIsAddingNewRow] = useState(false);
  const tableRef = useRef<TableReference>(null);

  const getObchodnici = async (): Promise<void> => {
    setLoading(true);
    try {
      const req = await axios.get("/users/fetch-obchodnici", {
        params: {
          admin: auth.user.id,
        },
      });

      setObchodnici(req.data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const editObchodnik = async (obchodnik: FetchedObchodnik) => {
    try {
      const splitFullName = obchodnik.fullName.split(" ");

      await axios.post("/users/edit", {
        id: obchodnik.id,
        admin: auth.user.id,
        user: {
          ...obchodnik,
          firstName: splitFullName[0] || "",
          lastName: splitFullName[1] || "",
        },
      });
      getObchodnici();
    } catch (err) {
      console.error(err);
    }
  };

  const deleteObchodnik = async (obchodnik: FetchedObchodnik) => {
    try {
      await axios.post("/users/delete", {
        id: obchodnik.id,
        admin: auth.user.id,
      });
      getObchodnici();
    } catch (err) {
      console.error(err);
    }
  };

  const addObchodnik = async (obchodnik: FetchedObchodnik) => {
    try {
      const splitFullName = obchodnik.fullName.split(" ");

      await axios.post("/users/add", {
        id: obchodnik.id,
        admin: auth.user.id,
        user: {
          ...obchodnik,
          firstName: splitFullName[0] || "",
          lastName: splitFullName[1] || "",
        },
      });
      getObchodnici();
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getObchodnici();
  }, []);

  const canEdit = auth.user.function === "root";

  const obchodniciColumns: Column[] = [
    { key: "id", visible: false },
    { key: "fullName", width: "20%", title: "Meno a priezvisko" },
    { key: "email", width: "20%", title: "EMAIL" },
    {
      key: "function",
      width: "20%",
      title: "Funkcia",
      placeholder: "obchodnik nebo root",
    },
    { key: "mobil", width: "20%", title: "Mobil" },
    {
      key: "last_online",
      width: "20%",
      title: "Naposledy prihlásený",
      editable: false,
    },
  ];

  if (canEdit) {
    obchodniciColumns.push({ key: "actions" });
  }

  if (loading) {
    return <Loading text="Získávanie obchodníkov zo servera" />;
  }

  return (
    <div className="pouzivatelia">
      <div className="title-wrapper">POUŽIVATELIA</div>
      {canEdit && (
        <div style={{ marginBottom: 8 }}>
          <Button
            disabled={isAddingNewRow}
            onClick={() => {
              setIsAddingNewRow(true);
              tableRef.current?.addNewRow();
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              {" "}
              <IoMdAdd
                size={16}
                style={{
                  marginRight: 10,
                }}
              />{" "}
              <div>PRIDAŤ</div>
            </div>
          </Button>
        </div>
      )}
      <Table
        ref={tableRef}
        onNewRowCancel={() => setIsAddingNewRow(false)}
        newRowHandler={(record: FetchedObchodnik) => {
          addObchodnik(record);
          setIsAddingNewRow(false);
        }}
        isDeletable={(record: Cell[]) => {
          return (
            record.find((cell) => cell.key === "function")?.value !== "root"
          );
        }}
        actions={canEdit}
        editHandler={editObchodnik}
        deleteHandler={deleteObchodnik}
        columns={obchodniciColumns}
        data={obchodnici.map((o) => {
          return {
            ...o,
            id: o.id,
            email: o.email ? o.email : "",
            fullName: o.fullName ? o.fullName : "",
            function: o.function,
            mobil: o.mobil ? o.mobil : "",
            last_online: o.last_online
              ? new Date(o.last_online).toLocaleDateString() +
                " " +
                new Date(o.last_online).toLocaleTimeString()
              : "Nikdy",
          };
        })}
      />
    </div>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Pouzivatelia);
